import { createContext, useReducer } from "react";
import { reducer } from "./reducer";
import { SecureDataKeys } from "../hooks/useAuthentication";
import { appLocalStorage } from "../utils/AppLocalStorage";
import { countries } from "../data/countries";

export const SecureStorageKeys = {
  darkMode: "XMO_darkMode",
  page: "XMO_page",
  isAuthenticated: "XMO_isAuthenticated",
  pageHistory: "XMO_pageHistory",
  queryParam: "XMO_queryParam",
  moneyTransferData: "XMO_moneyTransferData",
};

export const Store = createContext({} as any);
const currentTheme =
  appLocalStorage.getItem(SecureStorageKeys.darkMode) === "ON" ? true : false;
const currentPage = appLocalStorage.getItem(SecureStorageKeys.page) || "home";
const isAuthenticated =
  appLocalStorage.getItem(SecureStorageKeys.isAuthenticated) === "true"
    ? true
    : false;
const user: any = appLocalStorage.getItem(SecureDataKeys.accessToken)
  ? JSON.parse(appLocalStorage.getItem(SecureDataKeys.accessToken) as string)
  : null;
const param: any = appLocalStorage.getItem(SecureStorageKeys.queryParam)
  ? JSON.parse(appLocalStorage.getItem(SecureStorageKeys.queryParam) as string)
  : null;
const pageHistory: [] = appLocalStorage.getItem(SecureStorageKeys.pageHistory)
  ? JSON.parse(appLocalStorage.getItem(SecureStorageKeys.pageHistory) as string)
  : ["home"];
const moneyTransferData = appLocalStorage.getItem(
  SecureStorageKeys.moneyTransferData
)
  ? JSON.parse(
      appLocalStorage.getItem(SecureStorageKeys.moneyTransferData) as string
    )
  : null;
const initialState = {
  darkMode: currentTheme,
  currentPage,
  isAuthenticated,
  user,
  pageHistory: Array.from(new Set(pageHistory)),
  auth: { isLoading: false },
  param,
  toast: {
    show: false,
  },
  moneyTransferData,
  currencyState: {
    fromCurrency: countries.filter((country) => country.code === "USD")[0],
    toCurrency: countries.filter((country) => country.code === "GHS")[0],
  },
};

export function StoreProvider(props: any) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
