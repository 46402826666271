import { Avatar, Box, Grid, InputAdornment, Stack } from "@mui/material";
import AppTextField from "../../components/AppTextField";
import { useState } from "react";
import { countries } from "../../utils/countries";
import { paymentProviders } from "../LocalMoneyTransfer";

export const ReceiverMethods: any = [
  {
    name: "Mobile-Money",
    value: "mobile-money",
  },
  {
    name: "Bank Transfer",
    value: "bank-transfer",
  },
  {
    name: "Airtime",
    value: "airtime",
  },
];

export const TelcomProviders: any = [
  { name: "MTN", value: "mtn" },
  { name: "Vodafone", value: "voda" },
  { name: "Airtel-Tigo", value: "airtelTigo" },
];

// const BankProviders: any = [
//   { name: "GCB", value: "gcb" },
//   { name: "GTB", value: "gtb" },
//   { name: "Eco Bank", value: "eco" },
// ];

const ReceiverForm = (props: any) => {
  const {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    moneyTransferData,
  } = props;
  const [country, setCountry] = useState(countries[0]);
  const [receiverMethod, setReceiverMethod] = useState<string>(
    moneyTransferData.receiveMethod || values.receiveMethod
  );
  const [paymentProvider, setPaymentProvider] = useState<string>(
    paymentProviders.mtn
  );
  const handleChangeReceiverMethod = (e: any) => {
    handleChange(e);
    setReceiverMethod(e.currentTarget.value);
  };
  const handleChangePaymentProvider = (e: any) => {
    handleChange(e);
    setPaymentProvider(e.currentTarget.value);
  };
  const handleChangeCountry = (event: React.FormEvent<HTMLFormElement>) => {
    handleChange(event);
    const con = countries.filter(
      (coun: any) => coun.name === event.currentTarget.value
    );
    setCountry(con[0]);
  };
  return (
    <>
      <Stack sx={{ width: "inherit" }}>
        <Box>
          <AppTextField
            margin="normal"
            // label="Select a country"
            required
            fullWidth
            id="receiverCountry"
            name="receiverCountry"
            autoComplete="country"
            select
            SelectProps={{
              native: true,
            }}
            onBlur={handleBlur}
            onChange={handleChangeCountry}
            value={values.receiverCountry || ""}
            error={Boolean(touched.receiverCountry && errors.receiverCountry)}
            showErrorMessage={Boolean(
              touched.receiverCountry && errors.receiverCountry
            )}
            errorMessage={errors.receiverCountry}
          >
            {countries.map((country: any) => (
              <option value={country.name} key={country.name}>
                {country.name}
              </option>
            ))}
          </AppTextField>
        </Box>
        <Grid container spacing={{ md: 2 }}>
          <Grid item xs={12} md={6} lg={6}>
            <AppTextField
              margin="normal"
              required
              fullWidth
              label="First Name"
              autoComplete="fName"
              autoFocus
              id="receiverFName"
              name="receiverFName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.receiverFName || ""}
              error={Boolean(touched.receiverFName && errors.receiverFName)}
              showErrorMessage={Boolean(
                touched.receiverFName && errors.receiverFName
              )}
              errorMessage={errors.receiverFName}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <AppTextField
              margin="normal"
              required
              fullWidth
              label="Last Name"
              autoComplete="lName"
              id="receiverLName"
              name="receiverLName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.receiverLName || ""}
              error={Boolean(touched.receiverLName && errors.receiverLName)}
              showErrorMessage={Boolean(
                touched.receiverLName && errors.receiverLName
              )}
              errorMessage={errors.receiverLName}
            />
          </Grid>
        </Grid>
        <Box>
          <AppTextField
            type="email"
            margin="normal"
            required
            fullWidth
            autoComplete="email"
            label="Email"
            id="receiverEmail"
            name="receiverEmail"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.receiverEmail || ""}
            error={Boolean(touched.receiverEmail && errors.receiverEmail)}
            showErrorMessage={Boolean(
              touched.receiverEmail && errors.receiverEmail
            )}
            errorMessage={errors.receiverEmail}
          />
        </Box>
        <Grid container spacing={{ md: 2 }}>
          <Grid item xs={12} md={6} lg={6}>
            <AppTextField
              margin="normal"
              required
              fullWidth
              autoComplete="receiverMethod"
              value={receiverMethod}
              select
              SelectProps={{
                native: true,
              }}
              onChange={handleChangeReceiverMethod}
              id="receiverMethod"
              name="receiverMethod"
              onBlur={handleBlur}
              error={Boolean(touched.receiverMethod && errors.receiverMethod)}
              showErrorMessage={Boolean(
                touched.receiverMethod && errors.receiverMethod
              )}
              errorMessage={errors.receiverMethod}
            >
              {/* <option value="">Select a receiver method</option> */}
              {ReceiverMethods.map(
                (method: any) =>
                  method.value === "mobile-money" && (
                    <option value={method.value} key={method.name}>
                      {method.name}
                    </option>
                  )
              )}
            </AppTextField>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <AppTextField
              margin="normal"
              required
              fullWidth
              autoComplete="paymentProvider"
              value={paymentProvider}
              select
              SelectProps={{
                native: true,
              }}
              onChange={handleChangePaymentProvider}
              id="receiveChannel"
              name="receiveChannel"
              onBlur={handleBlur}
              error={Boolean(touched.receiveChannel && errors.receiveChannel)}
              showErrorMessage={Boolean(
                touched.receiveChannel && errors.receiveChannel
              )}
              errorMessage={errors.receiveChannel}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Avatar
                      alt={paymentProvider}
                      src={`images/${paymentProvider}.svg`}
                      sx={{ width: 30, height: 30 }}
                    />
                  </InputAdornment>
                ),
              }}
            >
              {/* <option value="">Select a payment method</option> */}
              {TelcomProviders.map(
                (provider: any) =>
                  provider.value === "mtn" && (
                    <option value={provider.value} key={provider.name}>
                      {provider.name}
                    </option>
                  )
              )}
              {/* {receiverMethod === ReceiverMethods[1].name &&
              BankProviders.map((provider: any) => (
                <option value={provider.value} key={provider.name}>
                  {provider.name}
                </option>
              ))} */}
            </AppTextField>
          </Grid>
        </Grid>
        <Box>
          <AppTextField
            type="text"
            margin="normal"
            required
            fullWidth
            autoComplete="receiverAccountNumber"
            label="Receiver Account Number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {country.dial_code}
                </InputAdornment>
              ),
            }}
            id="receiverAccountNumber"
            name="receiverAccountNumber"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.receiverAccountNumber || ""}
            error={Boolean(
              touched.receiverAccountNumber && errors.receiverAccountNumber
            )}
            showErrorMessage={Boolean(
              touched.receiverAccountNumber && errors.receiverAccountNumber
            )}
            errorMessage={errors.receiverAccountNumber}
          />
        </Box>
      </Stack>
    </>
  );
};

export default ReceiverForm;
