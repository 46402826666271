import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  styled,
  TextField,
} from "@mui/material";
import { useState } from "react";
const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-input": {
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "15px",
    borderColor: theme.palette.action.disabled,
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.action.hover,
  },
  "& .MuiInputLabel-root": {
    fontWeight: 500,
    color: theme.palette.text.disabled,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    fontWeight: 600,
  },
  "& .MuiSvgIcon-root": {
    color: theme.palette.text.disabled,
  },
  "& .MuiInputBase-root": { height: "45px" },
}));

const AppTextField = (props: any) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const { showErrorMessage, errorMessage, ...restProps } = props;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // height: "60px",
          flexDirection: "column",
        }}
      >
        {restProps.type === "password" ? (
          <StyledTextField
            {...restProps}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <StyledTextField {...restProps} type="text" fullWidth />
        )}
        {showErrorMessage && (
          <FormHelperText style={{ color: "red", fontSize: 8, marginTop: 0 }}>
            {errorMessage}
          </FormHelperText>
        )}
      </Box>
    </>
  );
};

export default AppTextField;
