const configField = "awsConfig";
const context: any = window;
type AppConfig = {
  Auth: {
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
  };
  xmoAPIKEY: string;
  xmoApiEndpoint: string;
  kudiApiEndpoint: string;
  square_app_id: string;
  square_location_id: string;
  paypal_sandbox_client_id: string;
  contact: string;
};

const awsConfig: AppConfig = context[configField];

export default awsConfig;
