import { CognitoUser } from "../typing";

export const SIGN_IN = {
  success: "UserAuthenticated",
  failure: "AuthenticationFailed",
  started: "AuthenticationStarted",
  logout: "LogoutUser",
  addRoles: "AddRoles",
};

export const AuthLoadingStatus = "Auth.Loading.Status";

export function loginSuccess(user: CognitoUser) {
  return {
    type: SIGN_IN.success,
    payload: user,
  };
}

export function loginFailure() {
  return {
    type: SIGN_IN.failure,
    payload: {},
  };
}

export function setAuthenticationLoadingStatus(status: boolean) {
  return {
    type: AuthLoadingStatus,
    payload: status,
  };
}

export function logoutUser() {
  return {
    type: SIGN_IN.logout,
    payload: {},
  };
}
export function addUserRoles(roles: Array<string>) {
  return {
    type: SIGN_IN.addRoles,
    payload: roles,
  };
}
