import { Box, Fab, Stack, Typography } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import HistoryIcon from "@mui/icons-material/History";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBack";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { useContext } from "react";
import { Store } from "../store";
import { useAppNavigation } from "../hooks/useAppNavigation";
import { SCREEN_NAME } from "../pages";
import { appLocalStorage } from "../utils/AppLocalStorage";
const TopNav = (props: any) => {
  const { setPage, goBackToPrevPage } = useAppNavigation();
  const {
    state: { user, pageHistory, darkMode },
    dispatch,
  } = useContext(Store);
  const goToLoginPage = () => {
    setPage(SCREEN_NAME.login);
  };
  const goToSignUpPage = () => {
    setPage(SCREEN_NAME.signUp);
  };
  const goToHomePage = () => {
    setPage(SCREEN_NAME.home);
  };

  const handleGoBackToPage = () => {
    if (pageHistory && pageHistory.length > 1) {
      goBackToPrevPage();
    } else {
      setPage(SCREEN_NAME.home);
    }
  };

  const handleChange = (newValue: string) => {
    appLocalStorage.setItem("page", newValue);
    dispatch({ type: "CHANGE_PAGE", payload: newValue });
  };
  return (
    <>
      <Stack
        sx={{
          justifyContent: "space-between",
          zIndex: 2,
          flexDirection: "row",
          position: "fixed",
          width: "100%",
          // margin: "0 auto",
          backgroundColor: "background.default",
          left: 0,
          top: 0,
          py: 1.5,
          px: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {props.showBackButton && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleGoBackToPage}
            >
              <ArrowBackIosIcon sx={{ color: "inherit" }} />
              <Typography sx={{ color: "inherit", fontWeight: "bold" }}>
                Back
              </Typography>
            </Box>
          )}
          {!props.showBackButton && (
            <Box
              sx={{
                width: 70,
                height: 30,
                zIndex: 2,
              }}
              onClick={goToHomePage}
            >
              <img
                width="100%"
                height="100%"
                src={
                  darkMode ? "images/logo-light.png" : "images/logo-primary.png"
                }
                alt="logo"
              />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            zIndex: 2,
            gap: {
              xs: 2,
              sm: 2,
              md: 3,
            },
          }}
        >
          <Fab
            aria-label="home"
            onClick={goToHomePage}
            sx={{
              display: "flex",
              justifySelf: "flex-start",
            }}
            size="small"
          >
            <CurrencyExchangeIcon color="primary" />
          </Fab>
          {!user && (
            <Fab
              aria-label="login"
              onClick={goToLoginPage}
              sx={{
                display: "flex",
                justifySelf: "flex-start",
              }}
              size="small"
            >
              <LoginIcon color="primary" />
            </Fab>
          )}
          {!user && (
            <>
              <Fab
                aria-label="signup"
                onClick={goToSignUpPage}
                sx={{
                  display: "flex",
                  justifySelf: "flex-start",
                }}
                size="small"
                color="primary"
              >
                <HowToRegIcon sx={{ color: "white" }} />
              </Fab>
            </>
          )}
          {user && (
            <Box display={{ md: "flex", xs: "none" }} sx={{ gap: 2 }}>
              <Fab
                aria-label="dashboard"
                onClick={() => handleChange(SCREEN_NAME.dashboard)}
                sx={{
                  display: "flex",
                  justifySelf: "flex-start",
                }}
                size="small"
              >
                <HomeIcon color="primary" />
              </Fab>
              <Fab
                aria-label="history"
                onClick={() => handleChange(SCREEN_NAME.history)}
                sx={{
                  display: "flex",
                  justifySelf: "flex-start",
                }}
                size="small"
              >
                <HistoryIcon color="primary" />
              </Fab>
              <Fab
                aria-label="settings"
                onClick={() => handleChange(SCREEN_NAME.settings)}
                sx={{
                  display: "flex",
                  justifySelf: "flex-start",
                }}
                size="small"
              >
                <SettingsIcon color="primary" />
              </Fab>
            </Box>
          )}
        </Box>
      </Stack>
    </>
  );
};

export default TopNav;
