import { SecureStorageKeys } from "../store";
import { ToastData, TransferModel } from "../typing";
import { appLocalStorage } from "../utils/AppLocalStorage";

export const actions = {
  darkModeOn: "DARK_MODE_ON",
  darkModeOff: "DARK_MODE_OFF",
  changePage: "CHANGE_PAGE",
  auth: "AUTH",
  saveUser: "SAVE_USER",
  goBack: "GO_BACK",
  setQueryParam: "SET_QUERY_PARAM",
  clearQueryParam: "CLEAR_QUERY_PARAM",
  showToast: "SHOW_TOAST",
  saveMoneyTransferData: "SAVE_MONEY_TRANSFER_DATA",
  removeMoneyTransferData: "REMOVE_MONEY_TRANSFER_DATA",
  setFromCurrency: "SET_FROM_CURRENCY",
  setToCurrency: "SET_TO_CURRENCY",
};

export function activateDarkMode(darkMode: boolean) {
  appLocalStorage.setItem(SecureStorageKeys.darkMode, "ON");
  return {
    type: actions.darkModeOn,
    payload: darkMode,
  };
}

export function deActivateDarkMode(darkMode: boolean) {
  appLocalStorage.setItem(SecureStorageKeys.darkMode, "OFF");
  return {
    type: actions.darkModeOff,
    payload: darkMode,
  };
}

export function changePage(page: string) {
  appLocalStorage.setItem(SecureStorageKeys.page, page);
  return {
    type: actions.changePage,
    payload: page,
  };
}

export function goBack() {
  return {
    type: actions.goBack,
  };
}

export function setIsAuthenticated(isAuthenticated: boolean) {
  appLocalStorage.setItem(
    SecureStorageKeys.isAuthenticated,
    `${isAuthenticated}`
  );
  return {
    type: actions.auth,
    payload: isAuthenticated,
  };
}

export function saveQuery(data: any) {
  appLocalStorage.setItem(SecureStorageKeys.queryParam, JSON.stringify(data));
  return {
    type: actions.setQueryParam,
    payload: data,
  };
}

export function clearQuery() {
  appLocalStorage.removeItem(SecureStorageKeys.queryParam);
  return {
    type: actions.clearQueryParam,
    payload: null,
  };
}

export function showToast(data: ToastData) {
  return {
    type: actions.showToast,
    payload: data,
  };
}

export function saveMoneyTransferData(data: TransferModel) {
  appLocalStorage.setItem(
    SecureStorageKeys.moneyTransferData,
    JSON.stringify(data)
  );
  return {
    type: actions.saveMoneyTransferData,
    payload: data,
  };
}

export function removeMoneyTransferData() {
  appLocalStorage.setItem(SecureStorageKeys.moneyTransferData, "");
  return {
    type: actions.removeMoneyTransferData,
    payload: null,
  };
}
export function setFromCurrency(value: any) {
  return {
    type: actions.setFromCurrency,
    payload: value,
  };
}
export function setToCurrency(value: any) {
  return {
    type: actions.setToCurrency,
    payload: value,
  };
}
