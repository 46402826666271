import {
  Avatar,
  Box,
  Stack,
  Typography,
  InputAdornment,
  CardContent,
  Card,
  Divider,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import AppTextField from "../components/AppTextField";
import { useContext, useEffect, useRef, useState } from "react";
import AppButton from "../components/AppButton";
import { Store } from "../store";
import * as Yup from "yup";
import { useFormik } from "formik";
import AppBottomSheet from "../components/AppBottomSheet";
import SquarePaymentGateWay from "../components/payment/SquarePaymentGateWay";
import { ReceiveMethod, TransferModel } from "../typing";
import { countries } from "../utils/countries";
import { closeLoader, isLimit, openLoader } from "../utils";
import LoadingModal from "../components/LoadingModal";
import useAPI from "../hooks/useApi";
import { TelcomProviders } from "./transferForm/Receiver";
import PaypalPaymentGateWay from "../components/payment/PaypalPaymentGateWay";
import { saveMoneyTransferData, showToast } from "../actions";
import { toastTypes } from "../components/AppToast";
import awsConfig from "../awsConfig";

export const limitMessage = (
  variant: string,
  paymentMethod: string,
  leftOver: number
) => `
You have exceeded your ${variant} limit for ${paymentMethod}. You can only send $${leftOver}. 
Please contact us for more details @ ${awsConfig.contact}
`;

export const paymentProviders = {
  mtn: "mtn",
  airtelTigo: "airtelTigo",
  voda: "voda",
};
export const paymentMethods: any = {
  paypal: "paypal",
  creditCard: "squareCreditCard",
  debitCard: "squareDebitCard",
  cashapp: "cashApp",
  cashApp: "cashApp",
};

export const limitPaymentMethods: any = {
  paypal: "payPal",
  squareCreditCard: "creditCard",
  squareDebitCard: "debitCard",
  cashApp: "cashApp",
};

const LocalMoneyTransfer = () => {
  const [loadPayment, setLoadPayment] = useState(false);
  const [rate, setRate] = useState<number>(NaN);
  const { getInternalRates, getOrderId, limit, history } = useAPI();
  const loadingRef = useRef<any>(null);
  const toastRef = useRef<any>(null);
  const [reference, setReference] = useState(null);
  // const toastRef = useRef<any>(null);
  const {
    state: { user, moneyTransferData },
    dispatch,
  } = useContext(Store);
  const paymentRef = useRef<any>(null);
  const [paymentMethod, setPaymentMethod] = useState<any>(
    moneyTransferData?.paymentMethod || paymentMethods.creditCard
  );
  const [paymentProvider, setPaymentProvider] = useState<any>(
    paymentProviders.mtn
  );
  const handleChangePaymentMethod: any = (e: any) => {
    getorderID(e.currentTarget.value);
    handleChange(e);
    setPaymentMethod(e.currentTarget.value);
    dispatch(
      saveMoneyTransferData({ ...values, paymentMethod: e.currentTarget.value })
    );
    window.location.reload();
  };
  const handleChangePaymentProvider = (e: any) => {
    handleChange(e);
    setPaymentProvider(e.currentTarget.value);
  };
  const handleOnCedisChange = (event: any) => {
    handleChange(event);
    const val = event.currentTarget.value;
    const dollarAmount = (Number(val) / +rate).toFixed(2);
    setFieldValue("dollarAmount", dollarAmount);
    setFieldValue("rate", rate);
    const isLimitResponse = isLimit(
      +dollarAmount,
      limit,
      history,
      "payWith",
      limitPaymentMethods[paymentMethod]
    );
    if (isLimitResponse.isLimit) {
      dispatch(
        showToast({
          show: true,
          message: limitMessage(
            isLimitResponse.variant,
            paymentMethod,
            isLimitResponse.leftOver
          ),
          ref: toastRef,
          type: toastTypes.error,
        })
      );
    }
  };

  const isLimitExceeded = () => {
    const isLimitResponse = isLimit(
      values.dollarAmount,
      limit,
      history,
      "payWith",
      limitPaymentMethods[paymentMethod]
    );
    if (isLimitResponse.isLimit) {
      dispatch(
        showToast({
          show: true,
          message: limitMessage(
            isLimitResponse.variant,
            paymentMethod,
            isLimitResponse.leftOver
          ),
          ref: toastRef,
          type: toastTypes.error,
        })
      );
    }

    return isLimitResponse;
  };

  const handleCalculateServiceCharge = (rate: number = 0.03) => {
    const overflow =
      Math.ceil(Number(values.dollarAmount)) - Number(values.dollarAmount);
    // in order not to overcharge a customer, we floor the service charge and add the amount left to round the actual payment to a whole number
    const serviceCharge =
      +Math.floor(rate * Number(values.dollarAmount)).toFixed(2) + overflow;
    // const serviceCharge = +rate * +Number(values.dollarAmount).toFixed(2);
    // console.log(Math.ceil(+serviceCharge), overflow, values.dollarAmount);
    const totalAmount: number = +serviceCharge + +values.dollarAmount;
    setFieldValue("totalAmount", totalAmount.toFixed(2));
    setFieldValue("serviceCharge", serviceCharge.toFixed(2));
    return serviceCharge;
  };

  const getRate = async () => {
    openLoader(loadingRef);
    try {
      const response = await getInternalRates();
      const { exchangeRates } = response;
      setRate(exchangeRates);
      closeLoader(loadingRef);
    } catch (error) {
      openLoader(loadingRef);
      console.log(error);
    }
  };

  const getorderID = async (paymentMethod: string) => {
    openLoader(loadingRef);
    try {
      const response = await getOrderId(paymentMethod);
      const { kudiexOrderId } = response;
      // console.log(response);
      setReference(kudiexOrderId);
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader(loadingRef);
    }
  };

  useEffect(() => {
    getRate();
    getorderID(paymentMethod);
    isLimitExceeded();
    // eslint-disable-next-line
  }, [limit, history]);
  const initialValues: TransferModel = {
    dollarAmount: moneyTransferData?.dollarAmount || 0,
    cedisAmount: moneyTransferData?.cedisAmount || 0,
    senderFName: moneyTransferData?.senderFName || user?.attributes?.given_name,
    senderLName:
      moneyTransferData?.senderLName ||
      user?.attributes?.family_name ||
      user?.username,
    senderEmail: moneyTransferData?.senderEmail || user?.attributes?.email,
    senderPhone:
      moneyTransferData?.senderPhone || user?.attributes?.phone_number,
    senderCountry: moneyTransferData?.senderCountry || "Ghana",
    receiverFName: moneyTransferData?.receiverFName || "",
    receiverLName: moneyTransferData?.receiverLName || "",
    receiverEmail: moneyTransferData?.receiverEmail || "",
    receiverPhone: moneyTransferData?.receiverPhone || "",
    receiverCountry: moneyTransferData?.receiverCountry || countries[0].name,
    receiveMethod:
      moneyTransferData?.receiveMethod || ReceiveMethod.mobileMoney,
    receiverAccountName: moneyTransferData?.receiverAccountName || "",
    receiverAccountNumber: moneyTransferData?.receiverAccountNumber || "",
    receiveChannel:
      moneyTransferData?.receiveChannel || TelcomProviders[0].value,
    paymentMethod:
      moneyTransferData?.paymentMethod || paymentMethods.creditCard,
    serviceCharge: 0,
    totalAmount: 0,
    authEmail: moneyTransferData?.senderEmail || user?.attributes?.email,
    authUsername: moneyTransferData?.authUsername || user?.username,
    rate: moneyTransferData?.rate || rate || 0,
    modeOfPayment: "payWith",
  };

  const validationSchema = Yup.object().shape({
    cedisAmount: Yup.number().min(1).required("Amount is required"),
    receiverAccountName: Yup.string()
      .trim()
      .min(1)
      .required("momo account name is required"),
    receiverAccountNumber: Yup.string()
      .trim()
      .min(10)
      .required("momo account number is required"),
    receiverEmail: Yup.string().trim().email("Invalid email"),
    receiveMethod: Yup.string().required("Receive method is required"),
    paymentMethod: Yup.string().required("Payment method is required"),
  });

  const {
    errors,
    values,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (formData) => {
      try {
        handleCalculateServiceCharge();
        setLoadPayment(true);
        handlePay();
        // console.log(formData);
      } catch (ex: any) {
        console.log(ex.message);
      }
    },
  });
  const handlePay = () => {
    openLoader(loadingRef);
    paymentRef.current.openSheet();
    closeLoader(loadingRef);
  };
  return (
    <>
      <Stack gap={1} sx={{ width: "100%" }}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ gap: 1, display: "flex", flexDirection: "column" }}
        >
          <Box>
            <AppTextField
              required
              label="Amount"
              autoComplete="amount"
              type="text"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">GHS</InputAdornment>
                ),
              }}
              inputProps={{
                inputMode: "numeric",
                style: { fontSize: 20 },
              }}
              id="cedisAmount"
              name="cedisAmount"
              onChange={handleOnCedisChange}
              onBlur={handleBlur}
              value={values.cedisAmount || ""}
              error={Boolean(touched.cedisAmount && errors.cedisAmount)}
              showErrorMessage={Boolean(
                touched.cedisAmount && errors.cedisAmount
              )}
              errorMessage={errors.cedisAmount}
            />
            <Typography
              sx={{
                fontSize: 10,
                textAlign: "left",
                fontWeight: "bold",
                px: 0.5,
                mt: 0.5,
              }}
            >
              {values.cedisAmount}GHS ≈ ${values.dollarAmount}
            </Typography>
          </Box>
          <Box>
            <AppTextField
              required
              fullWidth
              autoComplete="momo_acct_name"
              label="Momo Account Name"
              id="receiverAccountName"
              name="receiverAccountName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.receiverAccountName || ""}
              error={Boolean(
                touched.receiverAccountName && errors.receiverAccountName
              )}
              showErrorMessage={Boolean(
                touched.receiverAccountName && errors.receiverAccountName
              )}
              errorMessage={errors.receiverAccountName}
            />
          </Box>
          <Box>
            <AppTextField
              required
              fullWidth
              autoComplete="momo_acct_number"
              label="Momo Account Number"
              id="receiverAccountNumber"
              name="receiverAccountNumber"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.receiverAccountNumber || ""}
              error={Boolean(
                touched.receiverAccountNumber && errors.receiverAccountNumber
              )}
              showErrorMessage={Boolean(
                touched.receiverAccountNumber && errors.receiverAccountNumber
              )}
              errorMessage={errors.receiverAccountNumber}
            />
          </Box>
          <Box>
            <AppTextField
              fullWidth
              autoComplete="email"
              label="Receiver Email(Optional)"
              id="receiverEmail"
              name="receiverEmail"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.receiverEmail || ""}
              error={Boolean(touched.receiverEmail && errors.receiverEmail)}
              showErrorMessage={Boolean(
                touched.receiverEmail && errors.receiverEmail
              )}
              errorMessage={errors.receiverEmail}
            />
          </Box>

          <Stack>
            <Box>
              <AppTextField
                select
                fullWidth
                SelectProps={{
                  native: true,
                  IconComponent: KeyboardArrowDown,
                }}
                value={paymentProvider}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Avatar
                        alt={paymentProvider}
                        src={`images/${paymentProvider}.svg`}
                        sx={{ width: 30, height: 30 }}
                      />
                    </InputAdornment>
                  ),
                }}
                onChange={handleChangePaymentProvider}
                id="receiveChannel"
                name="receiveChannel"
                onBlur={handleBlur}
                error={Boolean(touched.receiveChannel && errors.receiveChannel)}
                showErrorMessage={Boolean(
                  touched.receiveChannel && errors.receiveChannel
                )}
                errorMessage={errors.receiveChannel}
              >
                <option value="">Select a payment provider</option>
                {TelcomProviders.map(
                  (provider: any) =>
                    provider.value === "mtn" && (
                      <option value={provider.value} key={provider.name}>
                        {provider.name}
                      </option>
                    )
                )}
              </AppTextField>
            </Box>
          </Stack>
          <Stack>
            <Box>
              <AppTextField
                select
                fullWidth
                SelectProps={{
                  native: true,
                  IconComponent: KeyboardArrowDown,
                }}
                onChange={handleChangePaymentMethod}
                value={paymentMethod}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Avatar
                        alt={paymentMethod}
                        src={`images/${paymentMethod}.svg`}
                        sx={{ width: 30, height: 30 }}
                      />
                    </InputAdornment>
                  ),
                }}
                id="paymentMethod"
                name="paymentMethod"
                onBlur={handleBlur}
                error={Boolean(touched.paymentMethod && errors.paymentMethod)}
                showErrorMessage={Boolean(
                  touched.paymentMethod && errors.paymentMethod
                )}
                errorMessage={errors.paymentMethod}
              >
                <option value="">Select a payment method</option>
                <option value={paymentMethods.paypal}>Paypal</option>
                <option value={paymentMethods.creditCard}>Credit Card</option>
                <option value={paymentMethods.cashapp}>Cash App</option>
              </AppTextField>
            </Box>
          </Stack>
          <AppButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            Pay
          </AppButton>
        </Box>
        <AppBottomSheet ref={paymentRef}>
          <Stack
            sx={{
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              gap: 3,
              mt: 5,
            }}
          >
            <Card
              sx={{
                width: 310,
                borderRadius: 5,
                height: 150,
                backgroundColor: "primary.main",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "column",
                    width: "100%",
                    gap: 2,
                    color: "white",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 15,
                    }}
                  >
                    Payment Summary
                  </Typography>
                  <Stack>
                    <Stack
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography sx={{ fontSize: 12 }}>Subtotal</Typography>
                      <Typography sx={{ fontSize: 12 }}>
                        ${values.dollarAmount}
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography sx={{ fontSize: 12 }}>Processing</Typography>
                      <Typography sx={{ fontSize: 12 }}>
                        ${values.serviceCharge}
                      </Typography>
                    </Stack>
                    <Divider sx={{ border: "2px solid white", my: 1 }} />
                    <Stack
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                        Total
                      </Typography>
                      <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                        ${values.totalAmount}
                      </Typography>
                    </Stack>
                    <Divider sx={{ border: "2px solid white" }} />
                    <Divider sx={{ border: "2px solid white" }} />
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
            {loadPayment && values.paymentMethod === paymentMethods.paypal && (
              <PaypalPaymentGateWay
                formData={values}
                loadPayment={loadPayment}
                reference={reference}
              />
            )}
            {loadPayment &&
              (values.paymentMethod === paymentMethods.creditCard ||
                values.paymentMethod === paymentMethods.cashapp) && (
                <SquarePaymentGateWay data={values} reference={reference} />
              )}
          </Stack>
        </AppBottomSheet>
        <LoadingModal ref={loadingRef} />
      </Stack>
    </>
  );
};

export default LocalMoneyTransfer;
