import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import HomeIcon from "@mui/icons-material/Home";
import HistoryIcon from "@mui/icons-material/History";
import SettingsIcon from "@mui/icons-material/Settings";
import { Paper, colors } from "@mui/material";
import { Store } from "../store";
import { useAppNavigation } from "../hooks/useAppNavigation";

type BottomNavProps = {
  handleSetScreen: any;
};

export default function BottomNav({ handleSetScreen }: BottomNavProps) {
  const { state } = React.useContext(Store);
  const { currentPage } = state;
  const { setPage } = useAppNavigation();

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setPage(newValue);
  };

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: colors.grey[300],
        width: "full",
        zIndex: 1100,
      }}
      elevation={8}
    >
      <BottomNavigation
        sx={{ width: "full" }}
        value={currentPage || "home"}
        onChange={handleChange}
      >
        <BottomNavigationAction value="dashboard" icon={<HomeIcon />} />
        <BottomNavigationAction value="home" icon={<CurrencyExchangeIcon />} />
        <BottomNavigationAction value="history" icon={<HistoryIcon />} />
        <BottomNavigationAction value="settings" icon={<SettingsIcon />} />
      </BottomNavigation>
    </Paper>
  );
}
