import { useContext } from "react";
import { Store } from "../store";
import { changePage, goBack } from "../actions";

export function useAppNavigation() {
  const { dispatch, state } = useContext(Store);
  const { currentPage } = state;
  const setPage = (page: string) => {
    dispatch(changePage(page));
  };
  const goBackToPrevPage = () => {
    dispatch(goBack());
  };
  return {
    currentPage,
    setPage,
    goBackToPrevPage,
  };
}
