import { Button } from "@mui/material";

const AppButton = (props: any) => {
  return (
    <Button
      {...props}
      variant={props.variant || "contained"}
      sx={{
        ...props.sx,
        borderRadius: "15px",
        paddingY: 2,
        height: "45px",
      }}
    />
  );
};

export default AppButton;
