import secureLocalStorage from "react-secure-storage";

export const appLocalStorage = {
  setItem: (key: string, value: string) => {
    try {
      secureLocalStorage.setItem(key, value);
    } catch (error) {
      console.log(error);
    }
  },
  getItem: (key: string) => {
    try {
      return secureLocalStorage.getItem(key);
    } catch (error) {
      console.log(error);
    }
  },
  removeItem: (key: string) => {
    try {
      secureLocalStorage.removeItem(key);
    } catch (error) {
      console.log(error);
    }
  },
  clearStorage: () => {
    try {
      secureLocalStorage.clear();
    } catch (error) {
      console.log(error);
    }
  },
};
