import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import "./PaypalPaymentGateWay.css";
import useAPI from "../../hooks/useApi";
import { closeLoader, getDeviceInfoAndIp, openLoader } from "../../utils";
import { useAppNavigation } from "../../hooks/useAppNavigation";
import { SCREEN_NAME } from "../../pages";
import { useContext, useRef } from "react";
import { Store } from "../../store";
import LoadingModal from "../LoadingModal";
import { removeMoneyTransferData } from "../../actions";

const PAY_PAL_SAND_BOX_ID = process.env.REACT_APP_PAYPAL_SAND_BOX_ID as string;

const PaypalPaymentGateWay = (props: any) => {
  const loadingRef = useRef<any>(null);
  const { setPage } = useAppNavigation();
  const { payPalPayment } = useAPI();
  const { formData, loadPayment, reference } = props;
  const deviceInfo = getDeviceInfoAndIp();
  const {
    // state: { user },
    dispatch,
  } = useContext(Store);

  function createOrder(data: any, actions: any) {
    openLoader(loadingRef);
    return actions.order.create({
      purchase_units: [
        {
          reference_id: reference,
          amount: {
            value: formData.totalAmount.toString(),
            currency_code: "USD", //dynamic in future
          },
        },
      ],
    });
  }
  async function onApprove(data: any) {
    const [momoFirstName, momoLastName] =
      formData.receiverAccountName.split(" ");

    try {
      const response = await payPalPayment({
        name: "moneyTransfer",
        category: "DIGITAL_GOODS",
        currencyCode: "USD",
        value: formData.totalAmount,
        phoneNumber: formData.senderPhone,
        email: formData.senderEmail,
        authEmail: formData.authEmail,
        authUsername: formData?.authUsername,
        paymentSource: formData.paymentMethod,
        payPalOrderId: reference,
        payerID: data.payerID,
        paymentID: data.orderID,
        orderID: data.orderID,
        reference: reference,
        kudiexOrderId: reference,
        orderId: reference,
        facilitatorAccessToken: data.facilitatorAccessToken,
        paymentInfo: {
          ...formData,
          receiverFName: momoFirstName,
          receiverLName: momoLastName,
          receiverPhone: formData.receiverAccountNumber,
        },
        mobileMoneyNumber: formData.receiverAccountNumber,
        momoFirstName,
        momoLastName,
        ...deviceInfo,
        success: true,
      });
      const { statusCode } = response; // const { payment, statusCode, success }
      if (statusCode === 200) {
        dispatch(removeMoneyTransferData()); // clear local memory log
        setPage(SCREEN_NAME.paymentSuccess);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(removeMoneyTransferData());
      closeLoader(loadingRef);
    }
  }
  return (
    <>
      <PayPalScriptProvider
        deferLoading={loadPayment ? false : true}
        options={{
          clientId: PAY_PAL_SAND_BOX_ID,
          intent: "capture",
          currency: "USD",
        }}
      >
        <PayPalButtons
          className="paypal"
          style={{ layout: "horizontal", height: 50, color: "black" }}
          createOrder={createOrder}
          onApprove={onApprove}
        />
      </PayPalScriptProvider>
      <LoadingModal ref={loadingRef} />
    </>
  );
};

export default PaypalPaymentGateWay;
