import { paymentMethods } from "../pages/LocalMoneyTransfer";

export function getDeviceInfoAndIp() {
  const devInfo = { ip: "", deviceId: window.navigator.userAgent };
  fetch("https://api.ipify.org/")
    .then((r) => r.text())
    .then((data) => (devInfo.ip = data))
    .catch((err) => console.log(err));

  return devInfo;
}

export const openLoader = (loadingRef: any) =>
  loadingRef.current && loadingRef.current.open();
export const closeLoader = (loadingRef: any) =>
  loadingRef.current && loadingRef.current.close();

export const isLimit = (
  currentAmount: number,
  limits: any,
  history: any,
  modeOfPayment: "payWith" | "mobileMoneyTransfer",
  paymentMethod: string
) => {
  let response: any = {
    isLimit: false,
    variant: "daily",
    leftOver: 0,
  };
  const limit = limits?.filter(
    (item: any) =>
      item.app === modeOfPayment && item.paymentMethod === paymentMethod
  )[0];
  const amountSentSoFar = getHistoryOutput(
    history,
    modeOfPayment,
    paymentMethods[paymentMethod]
  );
  // console.log(
  //   // amountSentSoFar.daily + +currentAmount,
  //   // amountSentSoFar.daily + +currentAmount > +limit?.daily,
  //   // amountSentSoFar.daily + +currentAmount <= +limit?.daily,
  //   // amountSentSoFar.weekly + +currentAmount <= +limit?.weekly,
  //   // amountSentSoFar.monthly + +currentAmount <= +limit?.monthly,
  //   amountSentSoFar,
  //   limit,
  //   paymentMethod,
  //   history
  // );
  // if (
  //   amountSentSoFar.weekly + +currentAmount <= +limit?.weekly &&
  //   amountSentSoFar.daily + +currentAmount <= +limit?.daily &&
  //   amountSentSoFar.monthly + +currentAmount <= +limit?.monthly
  // ) {
  //   return response;
  // }
  if (amountSentSoFar.weekly + +currentAmount > +limit?.weekly) {
    response.variant = "weekly";
    response.leftOver = +limit?.weekly - amountSentSoFar.weekly;
    response.isLimit = true;
    return response;
  }
  if (amountSentSoFar.daily + +currentAmount > +limit?.daily) {
    response.variant = "daily";
    response.leftOver = +limit?.daily - amountSentSoFar.daily;
    response.isLimit = true;
    return response;
  }
  if (amountSentSoFar.monthly + +currentAmount > +limit?.monthly) {
    response.variant = "monthly";
    response.leftOver = +limit?.monthly - amountSentSoFar.monthly;
    response.isLimit = true;
    return response;
  }
  return response;
};

const getHistoryOutput = (
  history: any,
  modeOfPayment: string,
  paymentMethod: string
) => {
  const date = new Date();
  const thisWeek = getStartAndEndWeek();
  const historySumDaily = history?.reduce((partialSum: number, a: any) => {
    if (
      a.paymentMethod === paymentMethod &&
      a.modeOfPayment === modeOfPayment &&
      new Date(a.pendingDate).toDateString() === date.toDateString()
    )
      return partialSum + +a.dollarAmount;
    return partialSum;
  }, 0);
  const historySumMonthly = history?.reduce((partialSum: number, a: any) => {
    if (
      a.paymentMethod === paymentMethod &&
      a.modeOfPayment === modeOfPayment &&
      new Date(a.pendingDate).getMonth() === date.getMonth()
    )
      return partialSum + +a.dollarAmount;
    return partialSum;
  }, 0);
  const historySumWeekly = history?.reduce((partialSum: number, a: any) => {
    if (
      a.paymentMethod === paymentMethod &&
      a.modeOfPayment === modeOfPayment &&
      new Date(a.pendingDate) > thisWeek.start &&
      new Date(a.pendingDate) <= thisWeek.end
    )
      return partialSum + +a.dollarAmount;
    return partialSum;
  }, 0);

  return {
    daily: historySumDaily,
    weekly: historySumWeekly,
    monthly: historySumMonthly,
  };
};

const getStartAndEndWeek = () => {
  // Get current date
  const today = new Date();

  // Get day of week
  const day = today.getDay();

  // Get the difference
  const diff = today.getDate() - day + (day === 0 ? -6 : 1);

  const startDate = new Date(today.setDate(diff));
  const endDate = new Date(today.setDate(diff + 6));

  return {
    start: startDate,
    end: endDate,
  };
};
